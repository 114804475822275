.bookingData {
  display: flex;
  justify-content: center;
  gap: 1.25rem;
}

.bookingDetail {
  border-radius: 1rem;
  background: #fff;
  padding: 1.3rem;
  width: 74%;
  height: fit-content;
  position: relative;
}

.bookingDetail > div {
  display: flex;
  justify-content: space-between;
}

.activityDetail {
  border-radius: 1rem;
  background: #fff;
  align-items: center;
  justify-content: center;
  width: 24%;
  height: 30rem;
  display: flex;
}

.leftBookingDetail {
  display: flex;
  flex-direction: column;
  gap: 1.38rem;
}

.leftBookingDetail h2 {
  color: #7c7c80;
  font-family: Inter;
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.leftBookingDetail h1 {
  color: #000;
  font-family: Inter;
  font-size: 1.75rem;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

.rightBookingDetail {
  display: flex;
  align-items: center;
  gap: 0.75rem;
  width: 58%;
}

.assignDetailBox,
.statusDetailBox {
  border-radius: 0.25rem;
  border: 1px solid #c5c5c5;
}

.assignDetailBox {
  width: 40%;
}

.statusDetailBox {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 50%;
}

.navline {
  margin-top: 2rem;
}

.belowBookingDetail {
  display: flex;
  flex-direction: column;
  gap: 1.3rem;
  margin-top: 2rem;
}

.belowBookingDetail h3 {
  color: #303030;
  font-family: Inter;
  font-size: 1rem;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.inputsBookingDetail {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 1rem;
}

.inputsBookingDetail > div {
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 0.6rem;
  gap: 1rem;
}

.inputsBookingDetail h2 {
  color: #7c7c80;
  font-family: Inter;
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.inputsBookingDetail input {
  border-radius: 0.25rem;
  border: 1px solid #c5c5c5;
  display: flex;
  width: 100%;
  padding: 0.875rem 2.875rem 0.875rem 0.75rem;
  align-items: center;
  gap: 0.625rem;
  color: #242426;
  font-family: Inter;
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.detailsButton {
  position: fixed;
  bottom: 0;
  left: 18%;
  width: 60%;
  display: flex;
  align-items: center;
  justify-content: flex-end !important;
  gap: 1.375rem;
  padding: 1rem;
  background-color: #fff;
  box-shadow: 0 -2px 5px rgba(0, 0, 0, 0.1);
  z-index: 100;
}

.cancelDetailButton {
  border-radius: 0.25rem;
  display: flex;
  padding: 0.7rem 3.03125rem;
  justify-content: center;
  align-items: center;
  gap: 0.625rem;
  border: 1px solid #ef4722;
  color: #ef4722;
  font-family: Inter;
  font-size: 1.125rem;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.approveDetailButton {
  display: flex;
  padding: 0.75rem 2.625rem;
  justify-content: center;
  align-items: center;
  gap: 0.625rem;
  border-radius: 0.25rem;
  background: #009600;

  color: #fff;
  font-family: Inter;
  font-size: 1.125rem;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.addItemLine {
  gap: 1.3rem;
  display: flex;
  width: 100%;
}

.addItemLine > div {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: left;
}

.addItemLine > .buttonSection {
  display: flex;
  justify-content: end;
  align-items: center;
}

.addItemLine > .buttonSection button {
  display: flex;
  padding: 10px 20px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 4px;
  background: #4b68fe;
  color: #fff;
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.addItemLine > div:nth-child(1) {
  width: 26%;
}

.addItemLine div:nth-child(2),
.addItemLine div:nth-child(3) {
  width: 13%;
}

.addItemLine div:nth-child(4) {
  width: 14%;
}

.addItemLine div:nth-child(5) {
  width: 20%;
}

.addItemLine input {
  border-radius: 0.25rem;
  border: 1px solid #c5c5c5;
  width: 100%;

  display: flex;
  height: 2.375rem;
  padding: 0.875rem 2.875rem 0.875rem 0.75rem;
  align-items: center;
  gap: 0.625rem;

  color: #242426;
  font-family: Inter;
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.belowBookingDetail h4 {
  color: #303030;
  font-family: Inter;
  font-size: 1.25rem;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.paymentSummaryPara,
.paymentSummaryParaHead {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.paymentSummaryPara h5 {
  color: #242426;
  font-family: Inter;
  font-size: 1rem;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  letter-spacing: 0.02rem;
}

.paymentSummaryParaHead {
  color: #242426;
  font-family: Inter;
  font-size: 1.125rem;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  letter-spacing: 0.0225rem;
  padding: 1.125rem 0rem;
  border-top: 1px dashed #d8d8dc;
  border-bottom: 1px dashed #d8d8dc;
}

.invoiceButton {
  display: flex;
  justify-content: end;
}

.invoiceButton button {
  border-radius: 0.25rem;
  background: #4b68fe;
  display: flex;
  padding: 0.75rem 1.1rem;
  justify-content: center;
  align-items: center;
  gap: 0.625rem;

  color: #fff;
  font-family: Inter;
  font-size: 1rem;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

/* ////// popup guest list ////////// */

/* Popup Container */
.popup-container {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background: rgba(0, 0, 0, 0.5);
  z-index: 1000;
}

/* Popup Box */
.popup {
  background: white;
  padding: 1.25rem;
  border-radius: 0.75rem;
  width: 45rem;
  min-height: 20rem;
  position: relative;
  left: 25%;
}

.popup-close {
  position: absolute;
  top: 20px;
  right: 10px;
  width: 1.5rem;
  height: 1.5rem;
  cursor: pointer;
}

.popup-close:hover {
  color: black;
}

.popup h1 {
  color: #000;
  font-family: Inter;
  font-size: 1.75rem;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  margin-top: 1.5rem;
}

.popup h2 {
  color: #242426;
  font-family: Inter;
  font-size: 1.125rem;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  padding-bottom: 1.25rem;
  border-bottom: 1px solid #d8d8dc;
}

.popup p {
  color: #242426;
  font-family: Inter;
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.outsidePopupDetails {
  display: flex;
  width: 100%;
  justify-content: space-between;
}

.insideGuestPopup {
  display: flex;
  align-items: center;
  justify-content: start;
  gap: 1.12rem;
  margin-top: 1.38rem;
}

.insideGuestPopup h3 {
  color: #7c7c80;
  font-family: Inter;
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}


.rs-input-group {
  border: none;
}
/* ///// food menu ////// */

.foodMenuDetail {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.foodMenuDetail div {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.5rem;
}

.foodMenuDetail h5 {
  color: #363638;
  font-family: Inter;
  font-size: 1rem;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: 0.02rem;
}

.childBookingDetail {
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid #d8d8dc;
  padding-bottom: 0.75rem;
}

.customCheckbox {
  width: 1.5rem;
  height: 1.5rem;

  /* fill: #fff;
  stroke-width: 1px;
  stroke: #4a6afe; */
}
