.name {
  color: #000;
  font-family: Inter;
  font-size: 1.75rem;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

.reservationId {
  color: #7c7c80;
  font-family: Inter;
  font-size: 1rem;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.details {
  color: #242426;
  font-family: Inter;
  font-size: 1.5rem;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.statusBox {
  display: flex;
  width: 45.5%;
  margin: 1.25rem auto;
  padding: 1rem;
  flex-direction: column;
  align-items: flex-start;
  border-radius: 0.75rem;
  border: 1px solid #d8d8dc;
}

.statusBox > p {
  color: #7c7c80;
  font-family: Inter;
  font-size: 1rem;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.statusBox > h2 {
  display: flex;
  height: 2.3rem;
  width: 7rem;
  justify-content: center;
  margin-top: 0.5rem;
  align-items: center;
  border-radius: 6.25rem;
  background: #cceacc;
  color: #009600;

  font-family: Inter;
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.statusBox > h3 {
  display: flex;
  width: 10rem;
  height: 2.5rem;
  margin-top: 0.5rem;
  padding: 0.875rem 1rem 0.875rem 0.5rem;
  align-items: center;
  flex-shrink: 0;
  border-radius: 0.25rem;
  background: #f0f1f3;
}

.biggerBox {
  display: flex;
}

.bookingDetails {
  display: flex;
  padding: 1rem 1rem 1rem 1rem;
  flex-direction: column;
  align-items: flex-start;
  gap: 1rem;
  border-radius: 0.75rem;
  border: 1px solid #d8d8dc;
  background: #fff;
  margin: 0 auto;
  width: 95%;
  justify-content: space-between;
  margin-bottom: 1.25rem;
}

.bookingDetails > h2 {
  color: #000;
  font-family: Inter;
  font-size: 1.125rem;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

.insideDetails {
  display: flex;
  justify-content: space-between;
  width: 100%;
  align-items: center;
}

.insideDetails > h3 {
  color: #7c7c80;
  font-family: Inter;
  font-size: 1rem;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.insideDetails > div {
  width: 60%;
  color: #242426;
  font-family: Inter;
  font-size: 1rem;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.menuCategory {
  display: flex;
  padding: 0.625rem 0rem;
  flex-direction: column;
  align-items: flex-start;
  border-radius: 0.75rem;
  border: 1px solid #d8d8dc;
  background: #fff;
  margin: 0 auto;
  width: 95%;
  justify-content: space-between;
}

.dropdownMenuWrapper {
  height: 50vh;
  overflow-y: auto;
  border-radius: 0.5rem;
  background: #fff;
  box-shadow: 0px 4px 12px 0px rgba(34, 34, 34, 0.16);
}

.dropdownMenuWrapperInvitee {
  height: 30vh;
  overflow-y: auto;
  border-radius: 0rem 0rem 0.5rem 0.5rem;
  background: #fff;
  /* box-shadow: 0px 4px 12px 0px rgba(34, 34, 34, 0.16); */
}

.dropdownMenuWrapper::-webkit-scrollbar,
.dropdownMenuWrapperInvitee::-webkit-scrollbar {
  width: 0.5rem;
}

.dropdownMenuWrapper::-webkit-scrollbar-track,
.dropdownMenuWrapperInvitee::-webkit-scrollbar-track {
  background-color: #f1f1f1;
}
.dropdownMenuWrapper::-webkit-scrollbar-thumb,
.dropdownMenuWrapperInvitee::-webkit-scrollbar-thumb {
  border-radius: 0.25rem;
}

.dropdownMenuWrapper,
.dropdownMenuWrapperInvitee {
  scrollbar-width: thin;
}

.menuCategoryHeading {
  border-bottom: 1px solid #e5e5ea;
  display: flex;
  padding: 1rem 1rem 1rem 1rem;
  align-items: center;
  justify-content: space-between;
}

.menuCategoryHeading > div:nth-child(1) {
  color: #303030;
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.menuCategoryHeading > div:nth-child(2),
.menuItemNameWrapper > div:nth-child(2) {
  color: #363638;
  font-family: Inter;
  font-size: 1rem;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: 0.32px;
}

.menuItemNameWrapper {
  display: flex;
  padding: 0.75rem 1rem 0.75rem 1rem;
  align-items: center;
  justify-content: space-between;
}
.menuItemNameWrapper > div:nth-child(1) {
  display: flex;
  align-items: center;
  gap: 0.625rem;
  color: #363638;
  font-size: 1rem;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: 0.02rem;
}

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.sort-wrapper-main-two {
  position: relative;
}

.dropdown-packageOptions-timeSlot-two,
.timeSlotDropdownIndividualParent {
  border: none;
  width: 100%;
  border-radius: 0.25rem;
  border: 1px solid #d8d8dc;
  background: #fff;
  box-shadow: 0px 4px 12px 0px rgba(34, 34, 34, 0.16);
  position: absolute;
  z-index: 5;
  top: 100%;
  left: 0;
  display: inline-flex;
  padding: 0.75rem;
  flex-direction: column;
  align-items: flex-start;
  gap: 0.625rem;
}

.dropdown-packageOption-timeSlot-two {
  display: flex;
  padding: 0.625rem 0.75rem;
  align-items: center;
  gap: 0.625rem;
  background: #fff;
  color: #242426;
  font-family: Inter;
  font-size: 1rem;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  cursor: pointer;
  width: 80%;
  border-radius: 0.25rem;
  border: 1px solid #d8d8dc;
}

.timeSlotDropdownIndividualParent {
  width: 100%;
}

.timeSlotDropdownIndividualContainer {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 1rem;
}

.timeSlotDropdownIndividual {
  padding: 0.625rem 0.65rem;
  width: 100%;
  text-align: center;
  background: #fff;
  color: #242426;
  font-family: Inter;
  font-size: 0.8rem;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  cursor: pointer;
  border-radius: 0.25rem;
  align-items: center;
  border: 1px solid #d8d8dc;
}

/* ***** date ********/

input[type="date"]:focus::-webkit-calendar-picker-indicator {
  cursor: pointer;
}

.custom-date-range-picker .rs-picker-toggle-clean {
  display: none;
}

.custom-date-range-picker .rs-picker-toggle-icon {
  position: absolute;
  right: 10px;
  top: 50%;
  transform: translateY(-50%);
  cursor: pointer;
}

.dropdown-packageOptions-slideOver {
  border: none;
  width: 15rem;
  padding: 1rem;
  border-radius: 0.25rem;
  background: #fff;
  box-shadow: 0px 4px 12px 0px rgba(34, 34, 34, 0.16);
  position: absolute;
  top: 2rem;
  right: 0rem;
  z-index: 5;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 0.625rem;
  /* border: 1px solid red; */
}

.dropdown-option-slideover {
  display: flex;
  align-items: center;
  gap: 0.625rem;
  background: #fff;
  color: #242426;
  font-family: Inter;
  font-size: 0.9rem;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  cursor: pointer;
  position: relative;
}

.inviteParent {
  display: flex;
  justify-content: end;
}

.inviteBttn2 {
  margin-top: 1rem;
  border-radius: 0.5rem;
  margin-right: 0.8rem;
  background: #4b68fe;
  display: flex;
  width: 10rem;
  padding: 0.8125rem 1.5rem;
  justify-content: center;
  align-items: center;
  gap: 0.625rem;

  color: #fff;
  font-family: Inter;
  font-size: 1rem;
  font-style: normal;
  font-weight: 600;
  line-height: 1.375rem;
}

.inviteBttn {
  margin-top: 1rem;
  border-radius: 0.5rem;
  margin-right: 0.8rem;
  background: #4b68fe;
  display: flex;
  width: 7.875rem;
  padding: 0.8125rem 1.5rem;
  justify-content: center;
  align-items: center;
  gap: 0.625rem;

  color: #fff;
  font-family: Inter;
  font-size: 1rem;
  font-style: normal;
  font-weight: 600;
  line-height: 1.375rem;
}

/* sliderover two phone input  */

.parentPhone .react-tel-input .form-control {
  border-radius: 0.25rem !important;
  border: 1px solid #c5c5c5 !important;
  background: white !important;
}

.parentPhone .flag-dropdown {
  border-radius: 0.25rem !important;
  border: 1px solid #c5c5c5 !important;
  border-radius: 0.25rem !important;
  padding-left: 0.5rem !important;
  padding-right: 0.5rem !important;
}

:where(.css-dev-only-do-not-override-1ae8k9u).ant-select-outlined:not(
    .ant-select-customize-input
  )
  .ant-select-selector {
  border: none !important;
}

/* /// */

.rs-picker-error > .rs-input-group {
  border-color: none !important;
  border-color: none !important;
}

.insideDetails .rs-picker-error > .rs-input-group {
  border: none;
}
